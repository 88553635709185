export default {
	data() {
		return {
			$_swMixin_url: null,
			$_swMixin_callback: null,
		};
	},
	methods: {
		setSWListener(url, callback) {
			this.$_swMixin_url = url;
			this.$_swMixin_callback = callback;
			if (navigator.serviceWorker) {
				navigator.serviceWorker.addEventListener('message', this.$_swMixin_onMessage);
			} else {
				console.log('Service Worker not supported');
			}
		},
		async $_swMixin_onMessage(event) {
			if (event.data.meta === 'workbox-broadcast-update') {
				const { cacheName, updatedURL } = event.data.payload;
				if (updatedURL.includes(this.$_swMixin_url)) {
					const fullPath = updatedURL.match(new RegExp(`api/${this.$_swMixin_url}.*`))[0];
					const cache = await caches.open(cacheName);
					const updatedResponse = await cache.match(fullPath);
					if (updatedResponse) {
						const updatedData = await updatedResponse.json();
						this.$_swMixin_callback(updatedData);
					}
				}
			}
		},
	},
	beforeDestroy() {
		navigator.serviceWorker.removeEventListener('message', this.$_swMixin_onMessage);
	},
};
