<template>
	<b-card>
		<div class="card-header border-0 py-2 pr-0 d-flex justify-content-between">
			<div>
				<h3 class="card-title mb-0">
					<span class="font-weight-bolder"> Add Devices to Site </span>
				</h3>
				<span class="text-muted font-weight-bold font-size-sm" v-if="loaded">
					{{ filteredDevices.length }} devices
				</span>
			</div>
			<div class="card-toolbar d-flex align-items-center">
				<b-button variant="primary" class="equal-height no-wrap mr-3" @click="getNewtecOptions()" v-b-modal.modal-1>Newtec Options</b-button>
				<b-form-select v-model="selectedType" :options="deviceOptions"></b-form-select>
				<div class="search-box ml-3" style="width: 450px">
					<b-form-input
						class="search-bar"
						v-model="searchText"
						placeholder="Search"
						debounce="500"
					/>
					<b-icon-search class="search-icon" />
				</div>
			</div>
		</div>
		<div class="card-body pt-2 pb-0" style="max-height: 600px; overflow-y: scroll">
			<ITCSpinner :loaded="loaded">
				<b-table
					:items="filteredDevices"
					:fields="fields"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:current-page="currentPage"
					:per-page="perPage"
				>
					<template #cell(TypeId)="data">{{ deviceTypes[data.item.TypeId].Name }}</template>
					<template #cell(Name)="data">
						<span
							class="svg-icon svg-icon-sm"
							@click="addDevice(data.item)"
							v-if="addLoading != data.item.Source"
						>
							<inline-svg src="/media/svg/icons/Code/Plus.svg"></inline-svg>
						</span>
						<b-spinner small :variant="'primary'" v-if="addLoading == data.item.Source" />
						{{ data.item.Name }}</template
					>
				</b-table>
			</ITCSpinner>
		</div>
		<div class="footer" v-if="filteredDevices.length > 0 && data.length > perPage">
			<b-pagination
				class="pagination"
				v-model="currentPage"
				:total-rows="filteredDevices.length"
				:per-page="perPage"
			/>
		</div>

		<!-- Modal -->
		<b-modal id="modal-1" title="Newtec Config" @hide="onModalHide">
			<p class="my-4">Enter Newtec modem prefix for all related modems to be added:</p>

			<div class="d-flex mb-3">
				<b-form-input v-model="newPrefix" placeholder="E.g., 'SD-SITE' for modems SD-SITE..." class="flex-grow-1 mr-2"></b-form-input>
				<b-button variant="primary" class="flex-shrink-0" style="white-space: nowrap;" :disabled="loading" @click="addNewtecPrefix(false)">Add Prefix</b-button>
			</div>

			<b-progress v-if="loading" :max="100" show-progress animated>
				<b-progress-bar :value="100">Loading...</b-progress-bar>
				
			</b-progress>
			<div v-if="loading">
				<div>Please wait a few minutes.</div>
				<span>The number of modems associated with the prefix will increase the loading time.</span>
			</div>
			<div v-if="showAlertNewtec" style="width:100%; text-align: center; font-size: 1.6em;" :class="{'newtec-success': isSuccessNewtec, 'newtec-error': !isSuccessNewtec}"> 
                {{ newtecMessage }}!
            </div>

			<b-table v-if="!loadingPrefixes":items="newtecPrefixes" :fields="newtecPrefixFields" class="align-middle">
				<template #cell(name)="data">
					<div class="d-flex align-items-center">
					{{ data.item.prefix }}
					</div>
				</template>
				<template #cell(actions)="data">
					<div class="d-flex align-items-center">
						<b-button variant="primary" :disabled="loading" @click="addNewtecPrefix(true, data.item.prefix)">update</b-button>
					</div>
				</template>
			</b-table>
			<!-- <b-progress v-else="loadingPrefixes" :max="100" show-progress animated> -->
				<div v-else class="d-flex justify-content-center">
					<b-spinner class="mt-5" medium :variant="'primary'"/>
				</div>
			<template #modal-footer>
				<b-button variant="secondary" @click="$bvModal.hide('modal-1')">Close</b-button>
			</template>
		</b-modal>
	</b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { REFRESH_ADMINEDIT_SITE } from '@/core/services/store/admin.module';
import swMixin from '@/core/services/mixins/serviceworker.mixin';

export default {
	name: 'SiteDeviceList',
	mixins: [swMixin],
	data() {
		return {
			data: [],
			loading: false,
			loaded: false,
			addLoading: false,
			loadingPrefixes: false,
			sortBy: 'TypeId',
			sortDesc: false,
			perPage: 10,
			searchText: '',
			currentPage: 1,
			fields: [
				{ key: 'TypeId', label: 'Type', sortable: true },
				{ key: 'Name', sortable: true },
				{ key: 'Source' },
			],
			selectedType: null,
			newPrefix: '',
			newtecPrefixes: [],
			newtecPrefixFields: [
				{ key: 'prefix', sortable: true, tdClass: 'align-middle' },
				{ key: 'timestamp', label: 'last update:', tdClass: 'align-middle'},
				{ key: 'actions', label: ''}
			],
			newtecMessage: '',
			showAlertNewtec: false,
			isSuccessNewtec: true,
		};
	},
	computed: {
		...mapGetters(['adminSite', 'deviceTypes']),
		siteDeviceIds() {
			if (this.adminSite.data.devices) {
				return this.adminSite.data.devices.map((d) => {
					return d.DeviceId;
				});
			}
			return [];
		},
		deviceOptions() {
			var dt = [{ value: null, text: 'Select Device Type' }];

			Object.keys(this.deviceTypes).forEach((id) => {
				dt.push({ value: id, text: this.deviceTypes[id].Name });
			});
			return dt;
		},
		filteredDevices() {
			const filterByTypeAndSearch = (device) => {
				const name = device.Name ? device.Name.toString().toLowerCase() : '';
				const source = device.Source ? device.Source.toString().toLowerCase() : '';
				const searchTextLower = this.searchText.toLowerCase();

				return device.TypeId == this.selectedType &&
					!this.siteDeviceIds.includes(device.DeviceId) &&
					(name.includes(searchTextLower) || source.includes(searchTextLower));
			};

			if (Array.isArray(this.data) && this.data.length > 0) {
				if (this.selectedType) {
					return this.searchText ?
						this.data.filter(filterByTypeAndSearch) :
						this.data.filter(device => device.TypeId == this.selectedType &&
												!this.siteDeviceIds.includes(device.DeviceId));
				} else {
					return this.searchText ?
						this.data.filter(device => {
							const name = device.Name ? device.Name.toString().toLowerCase() : '';
							const source = device.Source ? device.Source.toString().toLowerCase() : '';
							const searchTextLower = this.searchText.toLowerCase();

							return (name.includes(searchTextLower) || source.includes(searchTextLower)) &&
								!this.siteDeviceIds.includes(device.DeviceId);
						}) :
						this.data.filter(device => !this.siteDeviceIds.includes(device.DeviceId));
				}
			}
			return []; // Return an empty array if this.data is not an array or is empty
			// if (this.selectedType) {
			// 	if (!this.searchText) {
			// 		return this.data.filter((device) => {
			// 			return (
			// 				device.TypeId == this.selectedType && !this.siteDeviceIds.includes(device.DeviceId)
			// 			);
			// 		});
			// 	} else {
			// 		return this.data.filter((device) => {
			// 			return (
			// 				device.TypeId == this.selectedType &&
			// 				!this.siteDeviceIds.includes(device.DeviceId) &&
			// 				(device.Name.toString().toLowerCase().includes(this.searchText.toLowerCase()) ||
			// 					device.Source.toString().toLowerCase().includes(this.searchText.toLowerCase()))
			// 			);
			// 		});
			// 	}
			// }
			// if (!this.searchText)
			// 	return this.data.filter((device) => {
			// 		return !this.siteDeviceIds.includes(device.DeviceId);
			// 	});
			// return this.data.filter((device) => {
			// 	return (
			// 		(device.Name.toString().toLowerCase().includes(this.searchText.toLowerCase()) ||
			// 			device.Source.toString().toLowerCase().includes(this.searchText.toLowerCase())) &&
			// 		!this.siteDeviceIds.includes(device.DeviceId)
			// 	);
			// });
		},
	},
	methods: {
		addDevice(device) {
			this.addLoading = device.Source;
			this.$http
				.post('adddevice', { TypeId: device.TypeId, Source: device.Source, Name: device.Name })
				.then((resp) => {
					var newid = resp.data.data;
					if (newid && newid !== 0) {
						this.$http
							.post('sitedevice', { SiteId: this.adminSite.id, DeviceId: newid })
							.then(() => {
								this.$store.commit(REFRESH_ADMINEDIT_SITE);
								this.addLoading = false;
							});
					} else {
						this.addLoading = false;
					}
				});
		},
		onModalHide() {
			this.showAlertNewtec = false;
			this.newtecMessage = '';
		},
		getNewtecOptions() {
			this.loadingPrefixes=true;
			this.$http.get('newtec/modems/prefix_list')
				.then((res) => {
					this.newtecPrefixes = res.data;
				})
				.finally(()=>{
					this.loadingPrefixes=false;
				});
		},
		addNewtecPrefix(update, prefix=false) {
			this.showAlertNewtec = false;
			this.newtecMessage = '';
			this.loading = true;
			let url = ''; 
			if(this.newPrefix) {
				if(this.newPrefix.length<6) {
					this.showAlertNewtec = true;
					this.newtecMessage = 'invalid prefix';
					this.loading = false;
					return;
				}
				this.newPrefix = this.newPrefix.toUpperCase();
				url = `newtec/modems/update/${this.newPrefix}`
				this.showAlertNewtec = false;
			}
			if(update===true) {
				url = `newtec/modems/update/${prefix}?update=true`
			}
			
			if(!url) return;
			this.$http.get(url)
				.then((res) => {
					console.log(res)
					console.log(res.data)
					this.newPrefix = '';
					if(res.data.msg) {
						this.newtecMessage = res.data.msg;
						this.showAlertNewtec = true;
					}
					if(res.data.status==='created' || res.data.status==='updated') {
						this.getNewtecOptions();
						this.isSuccessNewtec = true;

						//Reload Device List for Newtec Modems to be included based on original device ID logic
						this.loaded = false;
						this.$http.get('devices').then((resp) => {
							this.data = resp.data.data;
							this.loaded = true;
						});
						this.setSWListener('devices', (data) => {
							this.data = data.data;
						});

					} else {
						this.isSuccessNewtec = false;
					}
				})
				.catch(err => {
					this.showAlertNewtec = true
					this.newtecMessage = err;
				})
				.finally(()=>{
					this.loading = false;
				})
    	},
	},
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	created() {
		this.loaded = false;
		this.$http.get('devices').then((resp) => {
			this.data = resp.data.data;
			this.loaded = true;
		});
		this.setSWListener('devices', (data) => {
			this.data = data.data;
		});
	},
};
</script>

<style scoped>
.search-box {
	position: relative;
}
.search-bar {
	padding-left: 30px;
}
.search-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}
.footer {
	padding: 15px 30px 0px;
}
.pagination {
	float: right;
}
.equal-height {
  /* height: 68%; */
  display: flex;
  align-items: center;
  width: 220px;
}
.no-wrap {
  white-space: nowrap; 
  overflow: hidden;   
  text-overflow: ellipsis;
}
.newtec-success {
	color: green;
}
.newtec-error {
	color: red;
}
</style>
